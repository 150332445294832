import { Controller } from '@hotwired/stimulus'
import { ClassicEditor, FullPage, Essentials, Bold, Italic, Font, Paragraph, Table, TableToolbar, Image } from 'ckeditor5'

export default class extends Controller {
  static values = {
    autoSubmit: Boolean
  }

  connect () {
    console.log('Email editor connected')

    ClassicEditor
      .create(this.element, {
        plugins: [Essentials, FullPage, Bold, Italic, Font, Paragraph, Table, TableToolbar, Image],
        toolbar: {
          items: [
            'undo', 'redo', '|', 'bold', 'italic', '|',
            'fontSize', 'fontFamily', 'fontColor', 'SourceEditing', '|',
            'insertTable', 'tableRow', 'mergeTableCells'
          ]
        },
        replace: true
      })
      .then(editor => {
        this.editor = editor
        if (this.autoSubmitValue) {
          editor.model.document.on('change:data', () => {
            this.debounceFormSubmission()
          })
        }
      })
      .catch(error => {
        console.error('Error initializing CKEditor', error)
      })
  }

  debounceFormSubmission () {
    clearTimeout(this.debounceTimeout)

    this.debounceTimeout = setTimeout(() => {
      this.submitForm()
    }, 2000)
  }

  submitForm () {
    const form = this.element.closest('form')
    if (form) {
      console.log('Submitting form...')
      form.requestSubmit()
    }
  }

  disconnect () {
    if (this.editor) {
      this.editor.destroy()
        .then(() => {
          console.log('Email editor destroyed')
        })
        .catch(error => {
          console.error('Error destroying CKEditor', error)
        })
    }

    clearTimeout(this.debounceTimeout)
  }
}
